<template>
<div class="section">
  <h1 class="title">Sig-o-matic 2 (alpha release)</h1>
  <div class="container">
    <div class="columns">
      <div class="column">
        <p>
          <strong>Instructions</strong>
          <br> Select all (⌘ + A) in the box below, then copy and paste into Gmail.
          Signature settings can be found in Settings &gt; General &gt; Signature.
        </p>
        <div contenteditable="true" class="content" style="padding: 10px;">
          -
          <br />
          <p v-if="seasonHeading"
           style="font-family: 'Arial';
                  font-weight: bold;
                  margin-bottom: 0;
                  font-size: 13px;
                  line-height: 17px;
                  color: #000000">
           {{ seasonHeading }}
          </p>
          <p v-if="seasonMessage"
            style="margin: 0;
                   font-family: 'Arial';
                   font-size: 13px;
                   line-height: 17px;
                   color: #666666 ">
            {{ seasonMessage }}
          </p>
          <p style="font-family: 'Arial'; font-size: 13px; line-height: 17px; color: #666666;">
            <b style="color: #000000; font-weight: bold;">{{ name }}</b>&nbsp;
            <span v-if="proNoun">({{ proNoun }})</span>
            <br />
            <b v-if="job">{{ job }}</b>
            <br v-if="job" />
            <a :href="`mailto:${ email }`"
              style="text-decoration: underline; color: #666666;">
              {{ email }}
            </a>
            <br />
            <span v-if="prePhoneMessage" :style="`font-family: 'Arial';
                         font-size: 13px;
                         line-height: 17px;
                         font-weight: bold;
                         color: ${messageColour }`">{{ prePhoneMessage }}</span>
            <br v-if="prePhoneMessage" />
            <span style="color: #666666" v-if="phoneOne">
              {{ phoneOne }}
            </span>
            <br v-if="phoneOne" />
            <span style="color: #666666" v-if="phoneTwo">{{ phoneTwo }}</span>
          </p>
          <p style="font-family: 'Arial'; font-size: 13px; line-height: 17px; color: #666666;">-</p>
          <p style="font-family: 'Arial'; font-size: 13px; line-height: 17px; color: #666666;">

            <img
              class="liq-logo"
              src="https://sig-o-matic.liquorice.net.au/LIQ-Email-BW-1-2.gif"
              width="200"
              height="120"
            >
            <br>
          </p>

          <p style="font-family: 'Arial'; font-size: 13px; line-height: 17px; color: #666666;">
            -
            <br/> <b style="color: #000000; font-weight: bold;">Liquorice</b>
            <span v-if="incPlacename"><br> Wurundjeri Country</span>
            <br> Level 1, 184 Brunswick St
            <br> Fitzroy, Victoria 3065
            <br>
            <a style="color: #666666">+61 3 6185 0234</a>
            <br>
            <a href="http://liquorice.com.au" style="text-decoration: underline; color: #666666;">
              liquorice.com.au
            </a>
          </p>
          <p style="font-family: 'Arial'; font-size: 13px; line-height: 17px; color: #666666;">
            We acknowledge the Wurundjeri people of the Kulin nation as the traditional
            custodians of the land on which we work. We pay our respects to their Elders past,
            present and future, and extend that respect to other Aboriginal and Torres Strait
            Islander people.
          </p>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" type="text" placeholder="Your name" v-model="name">
          </div>
        </div>
        <div class="field">
          <label class="label">Pronoun (optional)</label>
          <div class="control">
            <input class="input" type="text" placeholder="They/Them" v-model="proNoun">
          </div>
        </div>
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input class="input" type="text" v-model="email">
          </div>
        </div>
        <div class="field">
          <label class="label">Job</label>
          <div class="control">
            <input class="input" type="text" v-model="job">
          </div>
        </div>
        <div class="field">
          <label class="label">Pre-Phone Message Colour</label>
          <input class="input" type="color" v-model="messageColour">
        </div>
        <div class="field">
          <label class="label">Pre-Phone Message</label>
          <div class="control">
            <input class="input" type="text" v-model="prePhoneMessage">
          </div>
        </div>
        <div class="field">
          <label class="label">Phone One</label>
          <div class="control">
            <input class="input" type="text" v-model="phoneOne">
          </div>
        </div>
        <div class="field">
          <label class="label">Phone Two</label>
          <div class="control">
            <input class="input" type="text" v-model="phoneTwo">
          </div>
        </div>
        <div class="field">
          <label class="label">Season Heading</label>
          <div class="control">
            <input class="input" type="text" v-model="seasonHeading">
          </div>
        </div>
        <div class="field">
          <label class="label">Season Message</label>
          <div class="control">
            <input class="input" type="text" v-model="seasonMessage">
          </div>
        </div>
        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="incPlacename">
            Include traditional placename
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      name: '',
      proNoun: null,
      email: 'allsort@liquorice.com.au',
      job: '',
      prePhoneMessage: '',
      messageColour: '#000000',
      phoneOne: 'D +61 3 6185 0234',
      phoneTwo: 'M 0444 444 444',
      seasonHeading: '',
      seasonMessage: '',
      incPlacename: true,
      image: 'accountspayable.gif',
    };
  },
};
</script>
